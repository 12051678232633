import * as React from "react";
import { PopupButton } from '@typeform/embed-react'
import Layout from "../components/sitewide/layout";
import Seo from "../components/sitewide/seo";

export default function EmailSubscriptionConfirmation() {

  return (
    <Layout>
      <Seo title="Direct (waitlist)"/>
      <section
        className="mt-15vh mb-15vh motion-safe:animate-fade-in-1-5s text-center md:text-left">
        <div className="m-auto">
          <div
            className="m-auto md:inline-block w-full md:p-0 md:align-middle">
            <div className="align-middle">
              <div
                className="mb-10">
                <div>
                  <h1 className="mb-4 text-primary text-3xl font-black leading-normal text-center md:leading-snug">Platern Direct</h1>
                  {/*<p className="mb-10">In the UK, apps and websites authorised by <a className="text-primary" href="#">the FCA</a> help you to understand and control your finances with open banking.</p>*/}
                  {/*<p className="mb-6">We’re building a service to make fast bank transfers and read account data. Champion the customer experience by using our resources to connect directly to the open banking network.</p>*/}
                  <p className="mb-6 text-center">We're excited to accept applications to join the waitlist
                    to the Open Banking Direct platform.</p>
                  <p className="mb-6 text-center">Criteria for applying:</p>
                  <ul className="list-disc list-inside text-center">
                    <li className="text-center">have at least one UK bank account</li>
                    <li className="text-center">be able to use Web APIs</li>
                  </ul>
                  <div className="text-center my-10">
                    <PopupButton id="wwEOaqaF" style={{ fontSize: 20 }} className="m-auto bg-cta-primary text-cta-primary px-12 py-2 rounded relative origin-bottom-left">
                      Sign up for the waitlist
                    </PopupButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )

}